import Forest from './components/Forest'
import Bianca from './components/models/Bianca'
import * as THREE from 'three'

import { Canvas } from '@react-three/fiber'
import DebugGui from './components/debug/DebugGui'
import { Physics } from '@react-three/rapier'
import InteractUI from './components/UI/InteractUI'
import { useEffect, useRef, useCallback } from 'react'
import { initializeLocalStorageData } from './store/localstorageData'
import { useAtom } from 'jotai'
import {
  currentJobProjectAtom,
  floorLoadedAtom,
  insideHouseAtom,
  justTraveledFromWorldToWorldAtom,
} from './store/store'
import VisitingWorld from './components/VisitingWorld'
import BiancaCanvas from './components/BiancaCanvas'
import VisitingWorldUI from './components/UI/VisitingWorldUI'
import MushroomHouseInterior from './components/MushroomHouseInterior'
import { isMobile } from './helpers/helperFunctions'
import MobileControls from './components/MobileControls'
import { worlds_urls } from './interactable_data/jobs_projects_data'
import { initFirebase } from './firebase/init'
import LoadingScreen from './components/UI/LoadingScreen'
import useTriggerLoading from './hooks/TriggerLoading'
// import { useHelper } from '@react-three/drei'
// import { Perf } from 'r3f-perf'

const CanvasContent = () => {
  const [currentJobProject, setCurrentJobProject] = useAtom(currentJobProjectAtom)
  const [floorLoaded, setFloorLoaded] = useAtom(floorLoadedAtom)
  const [insideHouse] = useAtom(insideHouseAtom)
  const directional_light_ref = useRef<THREE.DirectionalLight>(null)

  // useHelper(directional_light_ref, THREE.DirectionalLightHelper, 1, 'red')

  const { triggerLoading } = useTriggerLoading()
  // const three = useThree()

  const [, setJustTraveledFromWorldToWorld] = useAtom(justTraveledFromWorldToWorldAtom)

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('MEMORY: ', three.gl.info.memory)
  //   }, 3000)

  //   // This will be the pixel ratio we passed to the Canvas through its dpr property
  //   // To see the original dpr of the device use window.devicePixelRatio
  //   console.log(three.gl.getPixelRatio())

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [three])

  useEffect(() => {
    setFloorLoaded(false)

    if (!currentJobProject) {
      setJustTraveledFromWorldToWorld(false)
    }
  }, [currentJobProject, setFloorLoaded, setJustTraveledFromWorldToWorld])

  const checkForPathnameWorld = useCallback(
    (trigger_loading?: boolean) => {
      const world_url = worlds_urls[window.location.pathname]

      if (world_url && world_url !== currentJobProject) {
        if (currentJobProject) {
          setJustTraveledFromWorldToWorld(true)
        }

        if (trigger_loading) {
          return triggerLoading(() => {
            setCurrentJobProject(world_url)
          })
        } else {
          setCurrentJobProject(world_url)
        }
      } else if (!world_url && currentJobProject) {
        if (trigger_loading) {
          triggerLoading(() => {
            setCurrentJobProject(null)
          })
        } else {
          setCurrentJobProject(null)
        }
      }
    },
    [currentJobProject, triggerLoading, setJustTraveledFromWorldToWorld, setCurrentJobProject]
  )

  useEffect(() => {
    initializeLocalStorageData()
    checkForPathnameWorld()
    initFirebase()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const checkWorldPathnameWithLoading = () => checkForPathnameWorld(true)
    window.addEventListener('popstate', checkWorldPathnameWithLoading)

    return () => {
      window.removeEventListener('popstate', checkWorldPathnameWithLoading)
    }
  }, [checkForPathnameWorld])

  const getMainScene = () => {
    if (currentJobProject) {
      return <VisitingWorld />
    }

    const getBianca = () => (floorLoaded ? <Bianca /> : null)

    const getScene = () => {
      if (insideHouse) {
        return <MushroomHouseInterior />
      }

      return <Forest />
    }

    return (
      <>
        {getScene()}
        {getBianca()}
      </>
    )
  }

  return (
    <>
      <DebugGui />
      <Physics>
        <directionalLight
          ref={directional_light_ref}
          castShadow={false}
          position={[-10, 15, 10]}
          intensity={2.5}
          shadow-mapSize={[1024, 1024]}
          shadow-camera-near={1}
          shadow-camera-far={10}
          shadow-camera-top={10}
          shadow-camera-right={10}
          shadow-camera-bottom={-10}
          shadow-camera-left={-10}
        />
        <ambientLight intensity={1.2} color={'#fff'} />

        {getMainScene()}
      </Physics>
    </>
  )
}

const App = () => {
  const [currentJobProject] = useAtom(currentJobProjectAtom)

  const getInteractUI = () => {
    if (currentJobProject) {
      return <VisitingWorldUI />
    }

    return <InteractUI />
  }

  const getMobileControls = () => {
    if (!isMobile()) return

    return <MobileControls />
  }

  const getBiancaCanvas = () => {
    if (!currentJobProject) return null

    return <BiancaCanvas />
  }

  return (
    <>
      <Canvas
        dpr={currentJobProject ? window.devicePixelRatio : 1}
        gl={{ toneMapping: THREE.LinearToneMapping, antialias: true }}
        camera={{
          fov: 45,
          near: 0.1,
          far: 200,
          position: [2.5, 4, 6],
        }}
      >
        {/* <Perf position="top-left" /> */}
        <CanvasContent />
      </Canvas>

      {getBiancaCanvas()}
      {getInteractUI()}
      {getMobileControls()}

      <LoadingScreen />
    </>
  )
}

export default App
