import { UAParser } from 'ua-parser-js'
import * as THREE from 'three'

const getRandomNumber = (min: number, max: number, use_decimals?: boolean) => {
  if (!use_decimals) {
    return Math.round(Math.random() * (max - min) + min)
  }

  return Math.random() * (max - min) + min
}

const getRandomColor = () => {
  return `hsla(${getRandomNumber(0, 360)}, ${getRandomNumber(45, 65)}%, ${getRandomNumber(40, 65)}%, 1.0)`
}

const isMobile = () => {
  // UAParser returns undefined for desktop: https://github.com/faisalman/ua-parser-js/issues/182
  const parserResults = UAParser()
  return !!parserResults.device.type
}

const isiOSMobile = () => {
  const parserResults = UAParser()
  return (parserResults.os.name === 'iOS' || parserResults.os.name === 'Mac OS') && isMobile()
}

const usingRecommendedBrowser = () => {
  const recommendedEngines = ['blink', 'webkit']
  const parserResults = UAParser()

  if (!parserResults.engine.name) return false

  return recommendedEngines.includes(parserResults.engine.name.toLowerCase())
}

const wait = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms)
  })
}

const determineYawToPointTo = (target_position: THREE.Vector3, object_position: THREE.Vector3) => {
  // Calculate direction vector from bodyB to bodyA
  const direction = new THREE.Vector3()
  direction.subVectors(target_position, object_position)

  // Calculate yaw angle - Note: z is the forward axis in Three.js
  // I have no idea how atan2 works
  const yaw = Math.atan2(direction.x, direction.z)

  // Convert radians to degrees
  let yawDegrees = THREE.MathUtils.radToDeg(yaw)
  if (yawDegrees < 0) yawDegrees += 360

  return yawDegrees
}

const shouldSpinYawClockwise = (current_yaw: number, target_yaw: number) => {
  const directions = [
    {
      name: 'forward_right',
      condition: (yaw: number) => yaw >= 112.5 && yaw < 157.5,
      clockwise_directions: ['backward_left', 'leftward', 'forward_left', 'forward'],
    },
    {
      name: 'forward_left',
      condition: (yaw: number) => yaw >= 202.5 && yaw < 247.5,
      clockwise_directions: ['backward', 'backward_left', 'leftward'],
    },
    {
      name: 'backward_right',
      condition: (yaw: number) => yaw >= 22.5 && yaw < 67.5,
      clockwise_directions: ['forward_left', 'forward', 'forward_right', 'rightward'],
    },
    {
      name: 'backward_left',
      condition: (yaw: number) => yaw >= 292.5 && yaw < 337.5,
      clockwise_directions: ['backward', 'backward_right', 'rightward', 'forward_right'],
    },
    {
      name: 'forward',
      condition: (yaw: number) => yaw >= 157.5 && yaw < 202.5,
      clockwise_directions: ['backward', 'backward_left', 'leftward', 'forward_left'],
    },
    {
      name: 'backward',
      condition: (yaw: number) => yaw >= 337.5 || yaw < 22.5,
      clockwise_directions: ['forward', 'forward_right', 'rightward', 'backward_right'],
    },
    {
      name: 'leftward',
      condition: (yaw: number) => yaw >= 247.5 && yaw < 292.5,
      clockwise_directions: ['rightward', 'backward_right', 'backward', 'backward_left'],
    },
    {
      name: 'rightward',
      condition: (yaw: number) => yaw >= 67.5 && yaw < 112.5,
      clockwise_directions: ['leftward', 'forward_left', 'forward', 'forward_right'],
    },
  ]

  const current_yaw_direction = directions.find((dir) => dir.condition(current_yaw))
  const target_yaw_direction = directions.find((dir) => dir.condition(target_yaw))

  if (!current_yaw_direction || !target_yaw_direction) {
    console.error('Error finding current or target yaw')
    console.log(current_yaw, target_yaw)
    return false
  }

  if (current_yaw_direction.name === target_yaw_direction.name) {
    const diff = current_yaw - target_yaw
    return diff > 0 ? diff < 180 : diff < -180
  }

  return target_yaw_direction.clockwise_directions.includes(current_yaw_direction.name)
}

const objectHasPropertiesValues = (
  objToCheck: { [key: string]: boolean },
  objToCheckAgainst: { [key: string]: boolean }
) => {
  for (const key of Object.keys(objToCheck)) {
    if (objToCheckAgainst[key] !== undefined && objToCheckAgainst[key] !== objToCheck[key]) {
      return false
    }
  }

  return true
}

export {
  getRandomNumber,
  getRandomColor,
  isMobile,
  wait,
  determineYawToPointTo,
  shouldSpinYawClockwise,
  objectHasPropertiesValues,
  isiOSMobile,
  usingRecommendedBrowser,
}
